// @flow
import InputError from "components/common/input-error/InputError";
import { Field, Formik } from "formik";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from 'services/domain/TemplatesService';
import { Button, Form, Grid } from "tabler-react";


const ModificationModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const onSubmit = (values, actions) => {
    props.contentTemplate.object = values.object;
    const contentTemplateBody = {
      object: values.object
    };
    TemplatesService.saveContentTemplate(props.template_id, props.content_id, contentTemplateBody)
      .then(saveContentTemplate => {
      })
    props.onHide && props.onHide();
    props.afterHide && props.afterHide(props.contentTemplate);
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('templates.object')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="object"
                    placeholder={props.t('templates.object')} />
                  <InputError name="object" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} closeButton
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('globals.edit')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.modify-object-modal-title')}</Modal.Title>
    </Modal.Header>
    {(props.contentTemplate) && (

      <Formik
        onSubmit={onSubmit}
        render={render}
        // validationSchema={ticketSchema.create}
        initialValues={{
          object: props.contentTemplate.object
        }}
      />
    )}
  </Modal>);
};

export default withI18n()(ModificationModal);
