// @flow

export const applicationEnabledValues = [{
  value: true,
  key: "yes"
}, {
  value: false,
  key: "no"
}
];


export const applicationServiceProviderValues = [
  {
    value: 1,
    key: "mandril"
  }, {
    value: 2,
    key: "aws-ses"
  }, {
    value: 3,
    key: "mailgun"
  }, {
    value: 4,
    key: "aws-ses-pmt"
  }
];

