/**
 * StringService
 * @module Services/utils/StringService
 * @description Handles string objects
 */

const self = {};

/**
 * Extract merge tags from strings ${key}
 * @author Mohamed CHEKIR
 * @alias extractMergeTags
 * @memberof module:Services/UtilsServices
 *
 * @param {string} char the character to verify
 *
 * @returns {bool} whether the character is a digit or not
 * @name UtilsService.isDigit
 */
self.extractMergeTags = (string) => {
  let keys = [];
  const myRegexp = new RegExp(/\$\{([^\\}]*(?:\\.[^\\}]*)*)\}/g, 'g'); // ${key}
  let match = myRegexp.exec(string);
  while (match != null) {
    // matched text: match[0]
    // match start: match.index
    // capturing group n: match[n]
    let _match = match[1];
    if (!keys.includes(_match)) {
      keys.push(_match)
    }
    match = myRegexp.exec(string);
  }
  return keys;
};

export default self;
