// @flow
import { emailSchema } from "@mkz/emailing-validation";
import InputError from "components/common/input-error/InputError";
import { Field, Formik } from "formik";
import { getDefaultContentLanguage, getLanguageByValue, getContentTemplateByLanguage } from "models/Template";
import React, { useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import EmailService from "services/domain/EmailService";
import ErrorHandler from "services/error/ErrorHandler";
import { Button, Form, Grid, Badge } from "tabler-react";
import StringService from 'services/utils/StringService';

const GenerateTestTemplateModal = (props): React.Node => {

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(getDefaultContentLanguage(props.template).language);

  const formRef = useRef();

  const onSubmit = (values, actions) => {
    setLoading(true);
    let body = {
      template_id: props.template.id,
      language: values.language,
      from: values.from,
      to: values.to,
      cc: values.cc,
      bcc: values.bcc,
      tags: (values.tags) ? JSON.parse(values.tags) : undefined,
      attachements: (values.attachements) ? JSON.parse(values.attachements) : undefined
    };
    let headers = {
      "client-id": props.application.id,
      "client-secret": props.application.client_secret
    }

    EmailService.sendEmail(body, headers)
      .then(data => {
        addToast(props.t(`emails.success-sending-message`), {
          appearance: 'success', autoDismiss: true
        });
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form innerRef={formRef} onSubmit={handleSubmit}>
          <Modal.Body>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.id')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    disabled={true}
                    name="template_id"
                    placeholder={props.t('globals.id')} />
                  <InputError name="template_id" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.language')}>
                  <Form.SelectGroup pills >
                    {props.template.template_content.map((content) => {
                      return (
                        <Form.SelectGroupItem
                          label={getLanguageByValue(content.language).label}
                          name="language"
                          value={getLanguageByValue(content.language).label}
                          onChange={(event) => {
                            const lang = getLanguageByValue(content.language);

                            setSelectedLanguage(lang.value);
                            setFieldValue('language', lang.label);
                          }}
                          checked={(values.language === getLanguageByValue(content.language).label)}
                        />
                      )
                    })}
                  </Form.SelectGroup>
                  <InputError name="language" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('emails.from')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="from"
                    placeholder={props.t('emails.from')} />
                  <InputError name="from" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('emails.to')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="to"
                    placeholder={props.t('emails.to')} />
                  <InputError name="to" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('emails.cc')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="cc"
                    placeholder={props.t('emails.cc')} />
                  <InputError name="cc" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('emails.bcc')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="bcc"
                    placeholder={props.t('emails.bcc')} />
                  <InputError name="bcc" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('emails.tags')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    component="textarea"
                    type="text"
                    name="tags"
                    placeholder={props.t('emails.tags')} />
                  <InputError name="tags" />
                  {props.t('emails.tags')} : &nbsp;
                  {(props.template?.template_content && StringService.extractMergeTags(JSON.stringify(getContentTemplateByLanguage(props.template, selectedLanguage)?.json_content)).map((mergeTag) => {
                    return (
                      <React.Fragment>
                        <Badge color="primary" className="">{mergeTag}</Badge> &nbsp;
                      </React.Fragment>)
                  }))}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('emails.attachements')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    component="textarea"
                    type="text"
                    name="attachements"
                    placeholder={props.t('emails.attachements')} />
                  <InputError name="attachements" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide}
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" onClick={props.onSubmit}
              color="primary" disabled={loading}
              loading={loading}>{props.t('globals.send')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.send-email-modal-title')}</Modal.Title>
    </Modal.Header>
    {(props.template) && (
      <Formik
        onSubmit={onSubmit}
        render={render}
        validationSchema={emailSchema.send}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          template_id: props.template.id,
          language: getLanguageByValue(getDefaultContentLanguage(props.template).language).label,
          output_type: "PDF",
          tags: props.template?.template_content && getTag(getContentTemplateByLanguage(props.template, selectedLanguage)?.json_content),
          attachements: `[
            {
                "tag": "logo",
                "height": 50,
                "content": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0*"
            }
        ]`,
        }}
      />
    )}
  </Modal>);
};

function getTag(json_content) {
  let _arrayTags = StringService.extractMergeTags(JSON.stringify(json_content));
  let _objectTags = {};
  for (let i = 0; i < _arrayTags.length; i++) {
    _objectTags[_arrayTags[i]] = ""
  }
  return JSON.stringify(_objectTags);
}

export default withI18n()(GenerateTestTemplateModal);
