// @flow
import Editable from '@mkz-wc/react-editable';
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from 'services/domain/TemplatesService';
import ErrorHandler from "services/error/ErrorHandler";
import StringService from 'services/utils/StringService';
import { Badge, Button, Form, Grid } from "tabler-react";


const HandelConditionModal = (props): React.Node => {

  // const [contentTemplate, setContentTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(null);

  const { addToast } = useToasts();

  useEffect(() => {
    setModel(props.condition)
  }, [props.condition]);

  const editCondition = (field) => {
    return (val) => {
      console.log("typeof here ", typeof val);
      console.log("model ", model);

      const request = {
        reference: model.reference
      };
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        console.log(typeof val);
        request[field] = val;
        model[field] = val;
      }
      setModel({ ...model });
      TemplatesService.createOrUpdateConditionBloc(props.template_id, props.content_id, request)
        .then(bloc_condition => {
          for (let i = 0; i < props.contentTemplate.bloc_conditions.length; i++) {
            if (props.contentTemplate.bloc_conditions[i].reference === props.condition.reference) {
              props.contentTemplate.bloc_conditions.splice(i, 1);
            }
          }
          props.contentTemplate.bloc_conditions.push(bloc_condition);
          props.contentTemplateStateHandler(props.contentTemplate);
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
    }
  };

  const deleteCondition = (field) => {

    setLoading(true);
    const request = {
      reference: model.reference,
      deletion_date: new Date()
    };

    TemplatesService.createOrUpdateConditionBloc(props.template_id, props.content_id, request)
      .then(template => {
        for (let i = 0; i < props.contentTemplate.bloc_conditions.length; i++) {
          if (props.contentTemplate.bloc_conditions[i].reference === props.condition.reference) {
            props.contentTemplate.bloc_conditions.splice(i, 1);
          }
        }
        props.contentTemplateStateHandler(props.contentTemplate);
        props.onHide();
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  }

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.handel-bloc-condition')}</Modal.Title>
    </Modal.Header>
    {(props.contentTemplate && model) && (
      <React.Fragment>
        <Modal.Body>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.condition')} : </h5></Grid.Col>
            <Grid.Col>
              <Form.Group className={"text-left"}>
                <Editable
                  initialValue={model.condition}
                  undefinedText={props.t('globals.no-value')}
                  isValueClickable={true}
                  onSubmit={editCondition('condition')}
                  type="textarea" />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.merge-tags')} : </h5></Grid.Col>
            <Grid.Col>
              {(props.contentTemplate && props.contentTemplate.html_content && StringService.extractMergeTags(props.contentTemplate.html_content).map((mergeTag) => {
                return (<React.Fragment>
                  <Badge color="primary" className="">{mergeTag}</Badge> &nbsp;
                </React.Fragment>)
              }))}
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={props.onHide} closeButton
            color="warning">{props.t('modals.close')}</Button>
          {(model && model.condition) && (
            <Button type="button" color="danger" disabled={loading} onClick={deleteCondition}
              loading={loading}>{props.t('globals.delete')}</Button>
          )}
        </Modal.Footer>
      </React.Fragment>
    )}
  </Modal>);
};

export default withI18n()(HandelConditionModal);
