import $ from "jquery";
import qs from "qs";
import React, { useEffect } from "react";
import { withI18n } from 'react-i18next';
import "./ApplicationDetails.scss";
import Referentiel from "./referentiels/Referentiel";
import MainTemplatesList from "./templates-list/MainTemplatesList";
import TemplatesList from "./templates-list/TemplatesList";

const ApplicationsDetails = ({ application, t }) => {

  useEffect(() => {
    window.history.pushState(null, null, "#referentiel");

    // EVENT LISTNET RESET QUERY PARAMS
    var elements = $(".Tab_header_tabs .nav-item");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', () => {
        resetQueryParams();
      }, false);
    }
  }, []);


  const resetQueryParams = () => {

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    queryParams.page = undefined;
    queryParams.limit = undefined;
    queryParams.search = undefined;

    window.history.pushState({ path: window.location.pathname }, '', `${window.location.pathname}?${qs.stringify(queryParams)}#referentiel`)

  }

  return (<React.Fragment>
    {(application) && (
      <React.Fragment>
        <Referentiel application={application} />
        <MainTemplatesList application={application} />
        <TemplatesList application={application} />
      </React.Fragment>
    )}
  </React.Fragment>)
};

export default withI18n()(ApplicationsDetails);
