import { ErrorMessage } from "formik";
import React from 'react';
import { withI18n } from "react-i18next";
import css from "./InputError.module.scss";


const InputError = ({ name, t, className }) => {

  const render = (error) => {
    switch (typeof error) {
      case 'object':
        if (error.length > 0) {
          return error.map((err, index) => {
            return Object.keys(err).map((key) => {
              return (<div className={className || css.error_message}>{t(`errors.E_${err[key].toUpperCase()}_${name.toUpperCase()}_${key.toUpperCase()}`)}</div>)
            })
          })
        }
        break;
      case 'string':
        return (<div className={className || css.error_message}>{t(`errors.E_${error.toUpperCase()}_${name.toUpperCase()}`)}</div>)
      default:
        return (<div className={className || css.error_message}>{t(`errors.E_${error.toUpperCase()}_${name.toUpperCase()}`)}</div>)
    }

  };

  const renderError = (error, name) => {
    return (<div className={className || css.error_message}>{t(`errors.E_${error.toUpperCase()}_${name.toUpperCase()}`)}</div>)
  };

  return (
    <ErrorMessage name={name} component="div" render={render} />)
};

export default withI18n()(InputError);
