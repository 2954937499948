import ProtectedRoute from "components/auth/ProtectedRoute";
import ApplicationDetailsPage from "pages/applications/application-details-page/ApplicationDetailsPage";
import ApplicationsPage from "pages/applications/application-page/ApplicationsPage";
import TemplateBlocConditionPage from "pages/template/template-bloc-condition/TemplateBlocConditionPage";
import BeeTemplatePage from "pages/template/template-edition/BeeTemplatePage";
import TemplatePage from "pages/template/template-edition2/TemplatePage";
import UsersPage from "pages/users/users-page/UsersPage";
import * as React from "react";
import { withNamespaces } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import AuthService from 'services/domain/AuthService';
import LinksProvider from 'services/http/LinksProvider';
import "tabler-react/dist/Tabler.css";
import Error404 from "./pages/404/Error404Page";
import HomePage from "./pages/home/HomePage";
import "./styles/App.scss";


function App(props): React.Node {

  return (<ToastProvider>
    <React.StrictMode>
      <Router>
        <Switch>

          <ProtectedRoute exact path={LinksProvider.ROUTES.HOME} component={HomePage} />

          <ProtectedRoute exact path={LinksProvider.ROUTES.USERS}
            component={UsersPage}
            authorization={AuthService.AUTHORIZATIONS.ADMIN_USER} />

          <ProtectedRoute exact path={LinksProvider.ROUTES.APPLICATIONS}
            component={ApplicationsPage} />

          <ProtectedRoute exact path={LinksProvider.ROUTES.APPLICATION}
            component={ApplicationDetailsPage} />

          <ProtectedRoute exact path={LinksProvider.ROUTES.TEMPLATE}
            component={BeeTemplatePage} />

          <ProtectedRoute exact path={LinksProvider.ROUTES.TEMPLATE2}
            component={TemplatePage} />

          <ProtectedRoute exact path={LinksProvider.ROUTES.TEMPLATE_BLOC_CONDITION}
            component={TemplateBlocConditionPage} />


          <Route component={Error404} />
        </Switch>
      </Router>
    </React.StrictMode>
  </ToastProvider>);
}

export default withNamespaces()(App);
