// @flow

export const EMAIL_STATUS = {
  FAIL : {
    key: "FAIL",
    value: 0
  },
  SEND: {
    key: "SEND",
    value: 1
  },
  DELIVERY: {
    key: "DELIVERY",
    value: 2
  },
  CLICK: {
    key: "CLICK",
    value: 3
  },
  OPEN: {
    key: "OPEN",
    value: 4
  },
  BOUNCE: {
    key: "BOUNCE",
    value: 5
  },
  REPLAYED:{
    key: "REPLAYED",
    value: 10
  }
};

export const getEmailStatus = (value) => {
  for (let key in EMAIL_STATUS) {
    if(EMAIL_STATUS[key].value === value) {
      return EMAIL_STATUS[key];
    }
  }
  return null;
}
