// @flow
import InputError from "components/common/input-error/InputError";
import { Field, Formik } from "formik";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import ApplicationsService from "services/domain/ApplicationsService";
import ErrorHandler from "services/error/ErrorHandler";
import { Button, Form, Grid } from "tabler-react";


const CreationModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const onSubmit = (values, actions) => {
    setLoading(true);

    ApplicationsService.createApplication(values)
      .then(application => {

        props.onHide && props.onHide();
        props.afterCreation && props.afterCreation(application);
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('applications.name')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="name"
                    placeholder={props.t('applications.name')} />
                  <InputError name="name" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} closeButton
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('globals.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('applications.create')}</Modal.Title>
    </Modal.Header>
    <Formik
      onSubmit={onSubmit}
      render={render}
      // validationSchema={ticketSchema.create}
      initialValues={{
      }}
    />
  </Modal>);
};

export default withI18n()(CreationModal);
