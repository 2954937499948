// @flow
import Editable from '@mkz-wc/react-editable';
import EmptyContent from 'components/common/empty-content/EmptyContent';
import { getLanguageByValue } from "models/Template";
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import StringService from 'services/utils/StringService';
import { ExternalLink, Eye, EyeOff } from 'tabler-icons-react';
import { Badge, Button, Form, Grid, Tab, Tabs } from "tabler-react";

const ViewContentTemplateModal = (props): React.Node => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const { addToast } = useToasts();


  useEffect(() => {
    props.template.default_language = getDefaultContentLanguage();
    setModel(props.template)
  }, [props.template]);

  function getDefaultContentLanguage() {
    if (props.template && props.template.template_content) {
      for (let key in props.template.template_content) {
        if (props.template.template_content[key].is_default) {
          return props.template.template_content[key].language;
        }
      }
    }
  };

  const editTemplate = (field) => {
    return (val) => {
      console.log("typeof here ", typeof val);

      console.log("field", field);
      console.log(" model[val]", val);
      console.log(" model[field]", model[field]);
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        console.log(typeof val);
        if (field == "main_template" || field == "enabled") {
          val = (val === true || val === "true") ? true : false;
          request[field] = val;
        } else if (field == "default_language") {
          val = parseInt(val);
          request[field] = val;
        } else {
          request[field] = val;
        }
        model[field] = val;
      }
      setModel({ ...model });
      console.log("$$$$ model: ", model);
      TemplatesService.update(props.template.id, request)
        .then(template => {
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));
      // updateProduct(request);
    }
  };

  const editContentTemplate = (field, key) => {
    return (val) => {
      console.log("typeof here ", typeof val);
      console.log("key ", key);

      console.log("field", field);
      console.log(" model[val]", val);
      console.log(" model[field]", model[field]);
      const request = {};
      request[field] = val;
      key[field] = val;

      TemplatesService.saveContentTemplate(props.template.id, key.id, request)
        .then(saveContentTemplate => {
        })
        .catch(error => ErrorHandler.handleError(addToast, props.t, error))
        .finally(() => setLoading(false));

      // setModel({...model});
      // console.log("$$$$ model: ", model);

    }
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.view-template-modal-title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(model) && (
        <Tabs initialTab={props.t('globals.referentiel')}>
          <Tab title={props.t('globals.referentiel')}>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.id')}</h5></Grid.Col>
              <Grid.Col>{model.id}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.name')}</h5></Grid.Col>
              <Grid.Col>
                <Form.Group className={"text-left"}>
                  <Editable
                    initialValue={model.name}
                    undefinedText={props.t('globals.no-value')}
                    isValueClickable={true}
                    onSubmit={editTemplate('name')}
                    type="textfield" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.description')}</h5></Grid.Col>
              <Grid.Col>
                <Form.Group className={"text-left"}>
                  <Editable
                    initialValue={model.description}
                    undefinedText={props.t('globals.no-value')}
                    isValueClickable={true}
                    onSubmit={editTemplate('description')}
                    type="textarea" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('globals.enabled')}</h5></Grid.Col>
              <Grid.Col>
                <Form.Group className={"text-left"}>
                  <Editable
                    initialValue={model.enabled}
                    undefinedText={props.t('globals.no-value')}
                    isValueClickable={true}
                    onSubmit={editTemplate('enabled')}
                    type="select" options={[{ label: props.t('globals.yes'), value: true }, { label: props.t('globals.no'), value: false }]}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.is-principal-template')}</h5></Grid.Col>
              <Grid.Col>
                <Form.Group className={"text-left"}>
                  <Editable
                    initialValue={model.main_template}
                    undefinedText={props.t('globals.no-value')}
                    isValueClickable={true}
                    onSubmit={editTemplate('main_template')}
                    type="select" options={[{ label: props.t('globals.yes'), value: true }, { label: props.t('globals.no'), value: false }]}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.default-language')}</h5></Grid.Col>
              <Grid.Col>
                <Form.Group className={"text-left"}>
                  <Editable
                    initialValue={model.default_language}
                    undefinedText={props.t('globals.no-value')}
                    isValueClickable={true}
                    onSubmit={editTemplate('default_language')}
                    type="select" options={props.template.template_content.map((template_content) => {
                      return {
                        label: getLanguageByValue(template_content.language).label,
                        value: template_content.language
                      }
                    })} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Tab>
          <Tab title={props.t('templates.content-email')}>
            <Grid.Row>
              <Grid.Col><h5>{props.t('templates.content-par-language')} :</h5></Grid.Col>
            </Grid.Row>
            <Tabs initialTab={((getDefaultContentLanguage() === 0) ? 'FR' : 'EN')}>
              {(props.template && props.template.template_content) && (
                props.template.template_content.map((key) => {
                  return (
                    <Tab title={((key.language === 0) ? 'FR' : 'EN')}>
                      <Grid.Row>
                        <Grid.Col><h5>{props.t('templates.content-id')}</h5></Grid.Col>
                        <Grid.Col>{key.id}</Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col><h5>{props.t('templates.object')}</h5></Grid.Col>
                        <Grid.Col>
                          <Form.Group className={"text-left"}>
                            <Editable
                              initialValue={(key.json_content?.subject) ? key.json_content?.subject : key.object}
                              undefinedText={props.t('globals.no-value')}
                              isEditable={!(key.json_content?.subject)}
                              isValueClickable={true}
                              onSubmit={editContentTemplate('object', key)}
                              type="textfield" />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col><h5>{props.t('templates.is-main-content')}</h5></Grid.Col>
                        <Grid.Col>{((key.is_default) ? props.t('globals.yes') : props.t('globals.no'))}</Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col><h5>{props.t('templates.merge-tags')}</h5></Grid.Col>
                        <Grid.Col>
                          {(key.json_content && StringService.extractMergeTags(JSON.stringify(key.json_content)).map((mergeTag) => {
                            return (<React.Fragment>
                              <Badge color="primary" className="">{mergeTag}</Badge> &nbsp;
                            </React.Fragment>)
                          }))}
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col><h5>{props.t('templates.edit-content')}</h5></Grid.Col>
                        <Grid.Col>
                          <span>
                            <a target="_blank" href={(key.json_content?.comment) ? LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE, { template_id: props.template.id, content_id: key.id })
                          : LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE2, { template_id: props.template.id, content_id: key.id })}>
                              Edit
                              <ExternalLink title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} />
                            </a>
                          </span>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col><h5>{props.t('templates.preview')}</h5></Grid.Col>
                        <Grid.Col >
                          <span class="pull-left">
                            {(showPreview === false) && (
                              <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowPreview(true) }} />
                            )}
                            {(showPreview === true) && (
                              <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowPreview(false) }} />
                            )}
                          </span>
                        </Grid.Col>
                      </Grid.Row>


                      {(showPreview) && (
                        <React.Fragment>
                          {(key.html_content) && (
                            <div className="preview_email content" dangerouslySetInnerHTML={{ __html: key.html_content }}></div>
                          )}
                          {(!key.html_content) && (
                            <React.Fragment>
                              <EmptyContent text={props.t('templates.no-content-template')} />
                              <Button.List align="center">
                                <a target="_blank" href={LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE, { template_id: props.template.id, content_id: key.id })}>
                                  <Button type="button" icon="plus" color="primary">{props.t('templates.define-content-template')}</Button>
                                </a>
                              </Button.List>
                            </React.Fragment>
                          )}
                        </React.Fragment>

                      )}


                    </Tab>
                  )
                })
              )}

            </Tabs>
          </Tab>
        </Tabs>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button type="button" onClick={props.onHide} closeButton
        color="warning">{props.t('modals.close')}</Button>
      {/* <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('modals.confirm')}</Button> */}
    </Modal.Footer>

  </Modal>);
};


export default withI18n()(ViewContentTemplateModal);
