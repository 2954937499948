// @flow
import InputError from "components/common/input-error/InputError";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import StringService from 'services/utils/StringService';
import { Badge, Button, Form, Grid } from "tabler-react";


const PreviewTagsModal = (props): React.Node => {

  // const [contentTemplate, setContentTemplate] = useState(null);
  const [loading, setLoading] = useState(false);

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('template.modify-modal')}>
                  <Form.Input
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="object"
                    value={values.object}
                    placeholder={props.t('template.object')} />
                  <InputError name="object" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} closeButton
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('globals.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.view-tags-modal-title')}</Modal.Title>
    </Modal.Header>
    {(props.contentTemplate) && (
      <React.Fragment>
        <Modal.Body>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.object')} : </h5></Grid.Col>
            <Grid.Col>
              {(props.contentTemplate && props.contentTemplate.object && StringService.extractMergeTags(props.contentTemplate.object).length === 0 && (
                <span>
                  {props.t('contents.no-tag')}
                </span>
              )
              )}
              {(props.contentTemplate && props.contentTemplate.object && StringService.extractMergeTags(props.contentTemplate.object).map((mergeTag) => {
                return (<Badge color="primary" className="mr-1">{mergeTag}</Badge>)
              }))}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col><h5>{props.t('templates.content-email')} : </h5></Grid.Col>
            <Grid.Col>
              {(props.contentTemplate && props.contentTemplate.html_content && StringService.extractMergeTags(props.contentTemplate.html_content).map((mergeTag) => {
                return (<React.Fragment>
                  <Badge color="primary" className="mr-1">{mergeTag}</Badge> &nbsp;
                </React.Fragment>)
              }))}
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={props.onHide} closeButton
            color="warning">{props.t('modals.close')}</Button>
          <Button type="submit" color="primary" disabled={loading}
            loading={loading}>{props.t('globals.create')}</Button>
        </Modal.Footer>
      </React.Fragment>
    )}
  </Modal>);
};

export default withI18n()(PreviewTagsModal);
