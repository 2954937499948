// @flow
import $ from 'jquery';
import { contentTemplateLanguage } from "models/Template";
import * as React from "react";
import { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import TemplatesService from "services/domain/TemplatesService";
import HandelConditionModal from "./handle-condition/HandelConditionModal";
import "./TemplateBlocCondition.scss";

function TemplateBlocConditionPage({ t, match }): React.Node {

  const [contentTemplate, setContentTemplate] = useState(null);
  const [conditionModalShown, setConditionModalShown] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [condition, setCondition] = useState(null);

  useEffect(() => {
    document.title = `${t('templates.title-edit-bloc-condition')}`;
    loadData();
  }, [t, match.params.id]);

  useEffect(() => {
    let blocs = $(".block-grid");
    console.log("blocs: ", blocs);
    blocs.each(function (index, elm) {
      $(this).on("click", function () {
        console.log("aaaaaa", $(this))
        if ($(this).attr("reference")) {
          let condition = {
            reference: $(this).attr("reference")
          };
          for (let i = 0; i < contentTemplate.bloc_conditions.length; i++) {
            if (contentTemplate.bloc_conditions[i].reference === $(this).attr("reference") && !contentTemplate.bloc_conditions[i].deletion_date) {
              condition = contentTemplate.bloc_conditions[i];
              break;
            }
          }
          setCondition(condition);
          setConditionModalShown(true);
        }
      })
    });
  }, [contentTemplate]);

  const loadData = () => {
    setLoadingCode(true);

    TemplatesService.getContentTemplate(match.params.template_id, match.params.content_id)
      .then((response) => {
        setContentTemplate(response);
      })

  };

  const contentTemplateStateHandler = (contentTemplate) => {
    console.log("contentTemplateStateHandler", contentTemplateStateHandler);
    setContentTemplate(contentTemplate);
  };

  return (
    <React.Fragment>

      {(contentTemplate) && (
        <div id="page-template-bloc-condition">

          <div class="plugin-toolbar-container">
            <div class="plugin-toolbar">
              <span class="plugin-toolbar-label">
                {t('templates.template-name')} : {contentTemplate.template.name}
              </span>
              <span class="plugin-toolbar-label">
                {t('globals.language')} : {(contentTemplateLanguage.map((language) => (language.value === contentTemplate.language) ? language.label : null))}
              </span>
              <span class="plugin-toolbar-label">
                {t('templates.object')} : {contentTemplate.object}
              </span>
            </div>
          </div>
          <div className="content" dangerouslySetInnerHTML={{ __html: contentTemplate.html_content }}></div>
          <HandelConditionModal
            condition={condition}
            contentTemplate={contentTemplate}
            contentTemplateStateHandler={contentTemplateStateHandler}
            content_id={match.params.content_id}
            template_id={match.params.template_id}
            afterHide={() => { }} show={conditionModalShown} onHide={() => setConditionModalShown(false)} />

        </div>
      )}
    </React.Fragment>
  )
}

export default withI18n()(TemplateBlocConditionPage);
