import jwt_decode from "jwt-decode";
import type { Principal } from "../../models/Principal";
import { HttpBackendClient } from "../http/HttpBackendService";
import LinksProvider from "../http/LinksProvider";

// const LS_TAG_USER_SCOPE = "user_scope";

let self = {};

self.AUTHORIZATIONS = {
  ADMIN_APPLICATION: "EMAILING_ADMIN_APPLICATION",
  ADMIN_USER: "EMAILING_ADMIN_USER",
  PRO_APPLICATION: "EMAILING_PRO_APPLICATION",
  PRO_TEMPLATE: "EMAILING_PRO_TEMPLATE"
}

/**
 * Checks if the current user is authenticated
 * @author Sameh Bellez
 * @alias isAuthenticated
 * @memberof module:Services/domain/AuthService
 * @returns {boolean}  Whether the current user is connected or not
 */
self.isAuthenticated = () => {
  return !!localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG);
};

/**
 * Gets the current connected principal
 * @author Sameh Bellez
 * @alias getPrincipal
 * @memberof module:Services/domain/AuthService
 * @returns {Principal}  The current connected principal
 */
self.getPrincipal = (): Principal => {
  if (!self.isAuthenticated()) {
    return null;
  }
  const data = jwt_decode(localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG));

  if (!data) {
    return null;
  }

  const user = { ...data };
  return (user: Principal);
};

/**
 * Gets the current access token from localStorage
 * @author Sameh Bellez
 * @alias getToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_BO_ACCESS_TOKEN_TAG);
};

/**
 * Get Server Token from Keycloak token
 * @author Hassen Charef
 * @alias validateTokenFromServer
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the request result
 */
self.validateTokenFromServer = async (keyCloak) => {
  if (!self.isAuthenticated()) {
    return HttpBackendClient.method(LinksProvider.API.AUTH.AUTH_TOKEN, null, keyCloak)
      .then(data => {
        return data;
      });
  }
};

export default self;
