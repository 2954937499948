import BeePlugin from '@mailupinc/bee-plugin';
import { getLanguageByValue, contentTemplateLanguage } from "models/Template";
import React, { useEffect, useState, useRef } from 'react';
import { withI18n } from "react-i18next";
import TemplatesService from 'services/domain/TemplatesService';
import LinksProvider from "services/http/LinksProvider";
import { Icon } from 'tabler-react';
import "./TemplatePage.scss";
import ModificationObjectModal from './modify-template/ModificationObjectModal';
import PreviewTagsModal from './modify-template/PreviewTagsModal';

// Move credentials to backend for production.
function TemplatePage({ t, match }): React.Node {

  const refIframe = useRef();
  const [contentTemplate, setContentTemplate] = useState(null);
  const [modificationObjectModalShown, setModificationObjectModalShown] = useState(false);
  const [previewTagsModalShown, setPreviewTagsModalShown] = useState(false);


  let contentPromise;

  useEffect(async () => {
    document.title = `${t('templates.title-edit-content')}`;
    contentPromise = onFetchTemplate();
    window.addEventListener("message", receiveMessage, false);
  }, []);

  const receiveMessage =  async (event) => {
    const message = event.data.message;

    switch (message) {
      case 'EditorReady':
        contentPromise.then((res) => {
          refIframe.current.contentWindow.postMessage({
            message: "AppData",
            metadata: {
              backLink: LinksProvider.formatFullUrl(window.location.origin, LinksProvider.ROUTES.APPLICATION, { id: res.template.application }),
              language: getLanguageByValue(res.language).label,
              name: res.template?.name,
            },
            value: res.json_content
          }, process.env.REACT_APP_EDITOR_URL);
        })
        break;
      case 'EditorSaveStart':
        break;
      case 'EditorCompileEnd':
        await onSave(event.data.value);
        refIframe.current.contentWindow.postMessage({
          message: "AppOnSaved",
        }, process.env.REACT_APP_EDITOR_URL);
        break;
    }
  }

  const onSave = (data) => {
    let contentTemplateBody = {
      json_content: JSON.stringify(data.json),
      html_content: data.html
    }
    return TemplatesService.saveContentTemplate(match.params.template_id, match.params.content_id, contentTemplateBody)
      .then(saveContentTemplate => {
      });
  };

  const onFetchTemplate = async () => {
    return TemplatesService.getContentTemplate(match.params.template_id, match.params.content_id)
      .then((response) => {
        setContentTemplate(response);
        return response;
      })
  };

  return (
    <React.Fragment>
      <div
        id='editor_wrapper'>
        <iframe
          ref={refIframe}
          width={"100%"}
          height={"100%"}
          style={{ height: "100%" }}
          src={`${process.env.REACT_APP_EDITOR_URL}/editor/secure`}
        />
      </div>
    </React.Fragment>
  )
}

export default withI18n()(TemplatePage);
