import DateService from "@mkz-service/date";
import React, { useEffect, useState } from "react";
import { withI18n } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import UsersService from "services/domain/UsersService";
import { Card, Table } from "tabler-react";
import "./UsersList.scss";



const UsersList = ({ t, users, applications }) => {
  const [applicationOptions, setApplicationOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const animatedComponents = makeAnimated();

  const headerItems = [{ content: "Nom Prénom" },
  { content: "email" },
  { content: "Date de création" },
  { content: "Applications" }];

  useEffect(() => {
    console.log("userEffect Applications", applications);
    let _applicationOptions = [];
    if (applications && applications.length > 0) {
      for (let i = 0; i < applications.length; i++) {
        _applicationOptions.push({ value: applications[i].id, label: applications[i].name })
      }
    }
    setApplicationOptions(_applicationOptions);
  }, [applications]);

  useEffect(() => {
    console.log("userEffect users", users);
    if (users && users.length > 0) {
      for (let i = 0; i < users.length; i++) {
        users[i].selectedValue = []
        for (let j = 0; j < users[i].applications.length; j++) {
          users[i].selectedValue.push(
            { value: users[i].applications[j].id, label: users[i].applications[j].name }
          );
        }
      }
    }
    setLoading(false);
  }, [users]);


  const itemRow = (user) => {
    return {
      key: user.id, item: [{
        content: (<span>{user.first_name} {user.last_name}</span>)
      },
      { content: (<span>{user.email}</span>) },
      { content: (<span>{DateService.formatDate(user.creation_date)}</span>) },
      {
        content: (
          <div style={{ "max-width": "250px" }}>

            <Select
              isMulti
              components={animatedComponents}
              defaultValue={user.selectedValue}
              options={applicationOptions}
              onChange={(selectedOption, event) => {
                console.log("onChange selectedOption", selectedOption)
                console.log("onChange removedItem", event)
                if (event) {
                  let body = null;
                  if (event.action === "clear") {
                    let applications = []
                    for (let i = 0; i < event.removedValues.length; i++) {
                      applications.push(event.removedValues[i].value);
                    }
                    body = {
                      action: "REMOVE",
                      application: applications
                    }
                  }
                  if (event.action === "remove-value") {
                    body = {
                      action: "REMOVE",
                      application: event.removedValue.value
                    }
                  }
                  if (event.action === "select-option") {
                    body = {
                      action: "ADD",
                      application: event.option.value
                    }
                  }
                  UsersService.updateUsersScope(user.id, body).then((data) => {
                    console.log("data", data);
                  })
                }
              }}
            />
          </div>
        )
      },

      ]
    };
  };

  return (<React.Fragment>
    <Card>
      <Card.Header>
        <Card.Title>{t('users.users-found')}</Card.Title>
      </Card.Header>
      <Card.Body>
        {(users && applications && !loading) && (

          <Table responsive
            className="card-table"
            headerItems={headerItems}
            bodyItems={users.map(itemRow)} />
        )}
      </Card.Body>
    </Card>
  </React.Fragment>)
};

export default withI18n()(UsersList);
