/**
 * EmailService
 * @module Services/http/EmailService
 * @description Loads users data from the server
 */

import { HttpBackendClient } from "../http/HttpBackendService";
import LinksProvider from "../http/LinksProvider";

const self = {};

self.sendEmail = (data, headers): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.EMAIL.SEND_EMAIL, null, data, headers)
    .then(data => data);
};

export default self;
