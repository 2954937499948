// @flow
import DateService from "@mkz-service/date";
import { EMAIL_STATUS, getEmailStatus } from "models/EmailHistory";
import { getLanguageByValue } from "models/Template";
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import JSONPretty from 'react-json-prettify';
import { github } from 'react-json-prettify/dist/themes';
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Eye, EyeOff } from 'tabler-icons-react';
import { Badge, Button, Grid, Tab, Tabs } from "tabler-react";

const ViewEmailModal = (props): React.Node => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showRequestContent, setShowRequestContent] = useState(false);
  const [showErrorBlocCondition, setShowErrorBlocCondition] = useState(false);

  const { addToast } = useToasts();

  useEffect(() => {
    setModel(props.email)
  }, [props.email]);

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('emails.view-email-modal-title')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {(model) && (
        <Tabs initialTab={props.t('emails.email')}>
          <Tab title={props.t('emails.email')}>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('globals.id')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.eh_id}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.subject')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.eh_subject}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.from')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.eh_from}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.to')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.eh_to}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.cc')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.eh_cc}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.bcc')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.eh_bcc}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.send-date')}</h5></Grid.Col>
              <Grid.Col sm="6">{DateService.formatTime(model.eh_send_date)}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.status')}</h5></Grid.Col>
              <Grid.Col sm="6">
                {(getEmailStatus(model.eh_status) && getEmailStatus(model.eh_status).key == EMAIL_STATUS.SEND.key) && (
                  <Badge color="primary" className="mr-1">{props.t('emails.status-send')}</Badge>
                )}
                {(getEmailStatus(model.eh_status) && getEmailStatus(model.eh_status).key == EMAIL_STATUS.DELIVERY.key) && (
                  <Badge color="primary" className="mr-1">{props.t('emails.status-delivery')}</Badge>
                )}
                {(getEmailStatus(model.eh_status) && getEmailStatus(model.eh_status).key == EMAIL_STATUS.CLICK.key) && (
                  <Badge color="success" className="mr-1">{props.t('emails.status-click')}</Badge>
                )}
                {(getEmailStatus(model.eh_status) && getEmailStatus(model.eh_status).key == EMAIL_STATUS.OPEN.key) && (
                  <Badge color="success" className="mr-1">{props.t('emails.status-open')}</Badge>
                )}
                {(getEmailStatus(model.eh_status) && getEmailStatus(model.eh_status).key == EMAIL_STATUS.FAIL.key) && (
                  <Badge color="danger" className="mr-1">{props.t('emails.status-fail')}</Badge>
                )}
                {(getEmailStatus(model.eh_status) && getEmailStatus(model.eh_status).key == EMAIL_STATUS.BOUNCE.key) && (
                  <Badge color="danger" className="mr-1">{props.t('emails.status-bounce')}</Badge>
                )}
              </Grid.Col>
            </Grid.Row>

            {(getEmailStatus(model.eh_status).key == EMAIL_STATUS.FAIL.key) && (
              <React.Fragment>
                <Grid.Row>
                  <Grid.Col sm="6"><h5>{props.t('emails.error-message')}</h5></Grid.Col>
                  <Grid.Col sm="6">
                    {model.eh_error_message}
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col sm="6"><h5>{props.t('emails.error-stack')}</h5></Grid.Col>
                  <Grid.Col sm="6">
                    {model.eh_execution_stack}
                  </Grid.Col>
                </Grid.Row>
              </React.Fragment>
            )}
            {(model.eh_error_bloc_condition) && (
              <Grid.Row>
                <Grid.Col sm="6"><h5>{props.t('emails.error-bloc-conditionnal-message')}</h5></Grid.Col>
                <Grid.Col sm="6">
                  <span class="pull-left">
                    {(showErrorBlocCondition === false) && (
                      <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowErrorBlocCondition(true) }} />
                    )}
                    {(showErrorBlocCondition === true) && (
                      <React.Fragment>
                        <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowErrorBlocCondition(false) }} />
                        <br />
                        <span>

                          {JSON.stringify(model.eh_error_bloc_condition)}
                        </span>
                      </React.Fragment>
                    )}
                  </span>
                  {/* {JSON.stringify(model.eh_error_bloc_condition)} */}
                </Grid.Col>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.request-content')}</h5></Grid.Col>
              <Grid.Col sm="6">
                <span class="pull-left mw-100">
                  {(showRequestContent === false) && (
                    <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowRequestContent(true) }} />
                  )}
                  {(showRequestContent === true) && (
                    <React.Fragment>
                      <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowRequestContent(false) }} />
                      <br />
                      <span>
                        <JSONPretty json={model.eh_request_content} theme={github} padding={4} />
                      </span>
                    </React.Fragment>
                  )}
                </span>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('emails.preview')}</h5></Grid.Col>
              <Grid.Col >
                <span class="pull-left">
                  {(showPreview === false) && (
                    <Eye title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowPreview(true) }} />
                  )}
                  {(showPreview === true) && (
                    <EyeOff title="voir" className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setShowPreview(false) }} />
                  )}
                </span>
              </Grid.Col>
            </Grid.Row>


            {(showPreview) && (
              <React.Fragment>
                {(model.eh_body) && (
                  <div className="content" dangerouslySetInnerHTML={{ __html: model.eh_body }}></div>
                )}
              </React.Fragment>

            )}
          </Tab>
          <Tab title={props.t('templates.template')}>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('templates.id')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.tp_id}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('globals.name')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.tp_name}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('globals.description')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.tp_description}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('templates.content-id')}</h5></Grid.Col>
              <Grid.Col sm="6">{model.tc_id}</Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col sm="6"><h5>{props.t('globals.language')}</h5></Grid.Col>
              <Grid.Col sm="6">{getLanguageByValue(model.tc_language).label}</Grid.Col>
            </Grid.Row>
          </Tab>
        </Tabs>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button type="button" onClick={props.onHide} closeButton
        color="warning">{props.t('modals.close')}</Button>
      {/* <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('modals.confirm')}</Button> */}
    </Modal.Footer>

  </Modal>);
};


export default withI18n()(ViewEmailModal);
