// @flow
import InputError from "components/common/input-error/InputError";
import { Formik } from "formik";
import _ from 'lodash';
import { contentTemplateLanguage } from "models/Template";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import { Button, Form, Grid } from "tabler-react";

const PlusContentTemplateModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const onSubmit = (values, actions) => {
    setLoading(true);
    TemplatesService.createContentTemplate(props.template.id, values)
      .then(template => {

        props.onHide && props.onHide();
        props.afterCreation && props.afterCreation(template);
      })
      .catch((error) => {
        ErrorHandler.handleError(addToast, props.t, error)
      })
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>

                <Form.Group label={props.t('globals.language')}>
                  <Form.SelectGroup pills >
                    {contentTemplateLanguage.map((language) => {
                      if (!_.find(props.template.template_content, { language: language.value })) {
                        return (
                          <Form.SelectGroupItem
                            label={language.label}
                            name="language"
                            value={language.value}
                            onChange={(event) => {
                              const value = language.value;
                              setFieldValue('language', event.target.value);
                            }}
                            checked={(parseInt(values.language) === language.value)}
                          />
                        )
                      }
                    })}
                  </Form.SelectGroup>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('contents.is-main-language')}>
                  <Form.SelectGroup pills >
                    <Form.SelectGroupItem
                      label="Oui"
                      name="is_default"
                      value={true}
                      disabled={true}
                      onChange={(event) => {
                        const value = true;
                        setFieldValue('is_default', value);
                      }}
                      checked={values.is_default == true}
                    />
                    <Form.SelectGroupItem
                      label="Non"
                      name="is_default"
                      value={false}
                      onChange={(event) => {
                        const value = false;
                        setFieldValue('is_default', value)
                      }}
                      checked={values.is_default == false}
                    />
                  </Form.SelectGroup>
                  <InputError name="is_default" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} closeButton
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('globals.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.plus-content-template-modal-title')}</Modal.Title>
    </Modal.Header>

    <Formik
      onSubmit={onSubmit}
      render={render}
      initialValues={{
        language: 0,
        is_default: false
      }}
    />
  </Modal>);
};

export default withI18n()(PlusContentTemplateModal);
