import DateService from "@mkz-service/date";
import SearchForm from "@mkz-wc/react-search-form";
import EmptyContent from "components/common/empty-content/EmptyContent";
import Table from "components/common/table/Table";
import $ from "jquery";
import { EMAIL_STATUS, getEmailStatus } from "models/EmailHistory";
import { contentTemplateLanguage } from "models/Template";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { withI18n } from 'react-i18next';
import { useToasts } from "react-toast-notifications";
import ApplicationsService from "services/domain/ApplicationsService";
import LinksProvider from "services/http/LinksProvider";
import { Eye } from 'tabler-icons-react';
import { Badge, Card, Loader } from "tabler-react";
import ViewEmailModal from "./email-action/ViewEmailModal";


const EmailHistoryList = ({ application, t, match }) => {

  const { addToast } = useToasts();
  const [search, setSearch] = useState(null);

  const [loading, setLoading] = useState(false);
  const [viewEmailModalShowIndex, setViewEmailModalShowIndex] = useState(-1);

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState(null);

  const loadData = () => {
    setLoading(true);


    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    let search = queryParams.search;
    let page = queryParams.page;
    let limit = queryParams.limit;

    ApplicationsService.getEmailHistory(application.id, { search, page, limit })
      .then(data => {
        setList(data.list);
        setPagination(data.pagination)
      })
      .finally(() => setLoading(false));
  };

  const paginationOnLoadingStart = () => {
    setLoading(true);
  };

  const paginationOnLoadingEnd = (data) => {
    setList(data.list);
    setPagination(data.pagination)
    setLoading(false);

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    queryParams.page = data.pagination.currentPage;
    queryParams.limit = data.pagination.limit;

    window.history.pushState({ path: window.location.pathname }, '', `${window.location.pathname}?${qs.stringify(queryParams)}#history`)
  };

  useEffect(() => {
    window.history.pushState(null, null, "#history");
    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
    loadData()
    // EVENT LISTNET RESET QUERY PARAMS
    var elements = $(".Tab_header_tabs .nav-item");
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', () => {
        resetQueryParams();
      }, false);
    }
  }, []);

  const resetQueryParams = () => {

    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    queryParams.page = undefined;
    queryParams.limit = undefined;
    queryParams.search = undefined;

    window.history.pushState({ path: window.location.pathname }, '', `${window.location.pathname}?${qs.stringify(queryParams)}#referentiel`)

  }

  const handelSearch = (search) => {
    loadData()
  };


  const headerItems = [
    { content: t('emails.template-name') },
    { content: t('globals.language') },
    { content: `${t('emails.from')}/${t('emails.to')}/${t('emails.subject')}` },
    { content: t('emails.send-date') },
    { content: t('emails.status') },
    { content: t('emails.details') }];


  const itemRow = (item, index) => {
    return {
      key: item.id, item: [
        {
          content: (<span>
            <a target="_blank" href={LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE, { template_id: item.tp_id, content_id: item.tc_id })}>
              {item.tp_name}
            </a>
          </span>)
        },
        {
          content: (<p class="text-center">
            <a target="_blank" href={LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE, { template_id: item.tp_id, content_id: item.tc_id })}>
              {contentTemplateLanguage.map((language) => {
                if (item.tc_language === language.value) {
                  return (
                    <img className="language-template" src={language.logo_src} />
                  )
                }
              })}
            </a>
          </p>)
        },
        {
          content: (
            <span>
              <span class="h6" >{t('emails.from')}:</span> {item.eh_from} <br />
              <span class="h6">{t('emails.to')}:</span> {item.eh_to} <br />
              <span class="h6">{t('emails.subject')}:</span> {item.eh_subject} <br />
            </span>
          )
        },
        { content: (<span>{DateService.formatTime(item.eh_send_date)}</span>) },
        {
          content: (<span>
            {(getEmailStatus(item.eh_status) && getEmailStatus(item.eh_status).key == EMAIL_STATUS.SEND.key) && (
              <Badge color="primary" className="mr-1">{t('emails.status-send')}</Badge>
            )}
            {(getEmailStatus(item.eh_status) && getEmailStatus(item.eh_status).key == EMAIL_STATUS.DELIVERY.key) && (
              <Badge color="primary" className="mr-1">{t('emails.status-delivery')}</Badge>
            )}
            {(getEmailStatus(item.eh_status) && getEmailStatus(item.eh_status).key == EMAIL_STATUS.CLICK.key) && (
              <Badge color="success" className="mr-1">{t('emails.status-click')}</Badge>
            )}
            {(getEmailStatus(item.eh_status) && getEmailStatus(item.eh_status).key == EMAIL_STATUS.OPEN.key) && (
              <Badge color="success" className="mr-1">{t('emails.status-open')}</Badge>
            )}
            {(getEmailStatus(item.eh_status) && getEmailStatus(item.eh_status).key == EMAIL_STATUS.FAIL.key) && (
              <Badge color="danger" className="mr-1">{t('emails.status-fail')}</Badge>
            )}
            {(getEmailStatus(item.eh_status) && getEmailStatus(item.eh_status).key == EMAIL_STATUS.BOUNCE.key) && (
              <Badge color="danger" className="mr-1">{t('emails.status-bounce')}</Badge>
            )}
          </span>)
        },
        {
          content: (
            <span>
              <Eye className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setViewEmailModalShowIndex(index) }} />
              <ViewEmailModal
                email={item}
                show={viewEmailModalShowIndex === index}
                onHide={() => setViewEmailModalShowIndex(-1)} />
            </span>)
        },
      ]
    };
  };

  return (<React.Fragment>

    <Card>
      <Card.Header>
        <Card.Title>{t('emails.email-history')}</Card.Title>
        <Card.Options>
          <SearchForm
            search={search}
            manageQueryParam={true}
            placeholder={t('history.search-placeholder')}
            onSubmit={handelSearch}
          />
        </Card.Options>
      </Card.Header>
      <Card.Body>

        {((!list || !list.length) && !loading) ? (
          <EmptyContent text={t('globals.no-results')} />) : ''}

        {((list && list.length) && !loading) ? (
          <Table responsive
            className="card-table"
            headerItems={headerItems}
            bodyItems={list.map(itemRow)}
            pagination={pagination}
            onLoadingStart={paginationOnLoadingStart}
            onLoadingEnd={paginationOnLoadingEnd}
          />
        ) : ''}

        {loading && <Loader />}

      </Card.Body>
    </Card>
  </React.Fragment>)
};

export default withI18n()(EmailHistoryList);
