// @flow
import { templateSchema } from '@mkz/emailing-validation';
import InputError from "components/common/input-error/InputError";
import { Field, Formik } from "formik";
import { contentTemplateLanguage } from "models/Template";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { withI18n } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import { Button, Form, Grid } from "tabler-react";

const CreationModal = (props): React.Node => {

  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const onSubmit = (values, actions) => {
    setLoading(true);
    console.log("props", props);
    console.log("values", values);
    values.application_id = props.application.id;
    TemplatesService.createTemplate(values)
      .then(template => {

        props.onHide && props.onHide();
        props.afterCreation && props.afterCreation(template);
      })
      .catch(error => ErrorHandler.handleError(addToast, props.t, error))
      .finally(() => setLoading(false));
  };

  const render = ({ handleSubmit, handleChange, handleBlur, values, setFieldValue, touched, errors }) => {
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.name')}>
                  <Field
                    class="form-control"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="name"
                    placeholder={props.t('globals.name')} />
                  <InputError name="name" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('globals.description')}>
                  <Form.Textarea
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    type="text"
                    name="description"
                    placeholder={props.t('globals.description')} />
                  <InputError name="description" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('templates.default-language')}>
                  <Form.SelectGroup pills >
                    {contentTemplateLanguage.map((language) => {
                      return (
                        <Form.SelectGroupItem
                          label={language.label}
                          name="default_language"
                          value={language.value}
                          onChange={(event) => {
                            const value = language.value;
                            setFieldValue('default_language', value);
                          }}
                          checked={(parseInt(values.default_language) === language.value)}
                        />
                      )
                    })}
                  </Form.SelectGroup>
                  <InputError name="default_language" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label={props.t('templates.define-as-main-template')}>
                  {(values.is_main_template) ? props.t('globals.yes') : props.t('globals.no')}
                  {/* <Form.SelectGroup pills >
                    <Form.SelectGroupItem
                      label="Oui"
                      name="is_main_template"
                      value={true}
                      disabled={true}
                      onChange={(event) => {
                        // const value = true;
                        // setFieldValue('is_main_template', value);
                      }}
                      checked={values.is_main_template == true}
                    />
                    <Form.SelectGroupItem
                      label="Non"
                      name="is_main_template"
                      value={false}
                      disabled={true}
                      onChange={(event) => {
                        // const value = false;
                        // setFieldValue('is_main_template', value)
                      }}
                      checked={values.is_main_template == false}
                    />
                  </Form.SelectGroup>
                  <InputError name="is_main_template" /> */}
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={props.onHide} closeButton
              color="warning">{props.t('modals.close')}</Button>
            <Button type="submit" color="primary" disabled={loading}
              loading={loading}>{props.t('globals.create')}</Button>
          </Modal.Footer>
        </Form>
      </React.Fragment>
    );
  };

  return (<Modal {...props} size="lg" centered>
    <Modal.Header>
      <Modal.Title>{props.t('templates.create-template')}</Modal.Title>
    </Modal.Header>

    {(props.application) && (
      <Formik
        onSubmit={onSubmit}
        render={render}
        validationSchema={templateSchema.create}
        initialValues={{
          default_language: 0,
          is_main_template: props.is_main_template,
          application_id: props.application.id
        }}
      />
    )}
  </Modal>);
};

export default withI18n()(CreationModal);
