import EmptyContent from "components/common/empty-content/EmptyContent";
import Table from "components/common/table/Table";
import ConfirmModal from "components/modals/confirm-modal/ConfirmModal";
import { contentTemplateLanguage, isAvailable as isTemplateAvailable } from "models/Template";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { withI18n } from 'react-i18next';
import { useToasts } from "react-toast-notifications";
import AuthService from "services/domain/AuthService";
import KeycloakService from "services/domain/KeycloakService";
import TemplatesService from "services/domain/TemplatesService";
import ErrorHandler from "services/error/ErrorHandler";
import LinksProvider from "services/http/LinksProvider";
import { Download, Edit, Plus, Trash } from 'tabler-icons-react';
import { Badge, Card, Loader } from "tabler-react";
import "../ApplicationDetails.scss";
import AddContentModal from "./template-action/AddContentModal";
import CreateTemplateModal from "./template-action/CreateTemplateModal";
import ViewEditTemplateModal from "./template-action/ViewEditTemplateModal";


const MainTemplatesList = ({ application, t }) => {

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(-1);
  const [editModalShowIndex, setEditModalShowIndex] = useState(-1);
  const [plusModalShownIndex, setPlusModalShownIndex] = useState(-1);
  const [creationModalShown, setCreationModalShown] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sort, setSort] = useState(null);

  const loadData = () => {
    setLoading(true);


    let queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    TemplatesService.getAppTemplates(application.id, { main_template: true })
      .then(data => {
        setTemplates(data.list)
        setPagination(data.pagination);
        setSort(data.sort);
      })
      .finally(() => setLoading(false));
  };

  const paginationOnLoadingStart = () => {
    setLoading(true);
  };

  const paginationOnLoadingEnd = (data) => {
    setTemplates(data.list);
    setPagination(data.pagination);
    setSort(data.sort);
    setLoading(false);
  };

  const exportTemplate = (template_id) => {
    TemplatesService.exportTemplate(template_id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `template-${template_id}.json`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    loadData()
  }, []);

  const handelSearch = (search) => {
    loadData()
  };

  const deleteTemplate = () => {
    let index = deleteModalShown;
    setLoading(true);
    let request = {
      deletion_date: new Date()
    }
    return TemplatesService.update(templates[index].id, request)
      .then(template => {
        templates.splice(index, 1);
        return template;
        // TODO redirection to /applications
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
      })
      .finally(() => {
        setLoading(false);
        setDeleteModalShown(-1);
      });
  };

  const headerItems = [{ content: "Nom" },
  { content: "Contenu" },
  { content: "Description" },
  { content: "Statut" },
  { content: "Actions" }];


  const itemRow = (template, index) => {
    return {
      key: template.application.id, item: [
        {
          content: (
            <span>
              {template.name} &nbsp;
              <Badge color="primary" className="mr-1">{t('globals.default')}</Badge>
            </span>)
        },
        {
          content: (
            <span>
              {
                template.template_content.map((template_content) => {
                  return (
                    <React.Fragment>
                      {console.log("******* template_content?.json_content", template_content.json_content)}
                      <a target="_blank" href={
                        // CHANGE ME : SET ONE TEMPLATE (HOME MADE)
                        (template_content?.json_content?.comment) ? LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE, { template_id: template.id, content_id: template_content.id })
                          : LinksProvider.formatUrl(LinksProvider.ROUTES.TEMPLATE2, { template_id: template.id, content_id: template_content.id })}>
                        {contentTemplateLanguage.map((language) => {
                          if (template_content.language === language.value) {
                            return (
                              <img className="language-template" src={language.logo_src} />
                            )
                          }
                        })}
                      </a>
                    </React.Fragment>
                  )
                })
              }
            </span>
          )
        },
        { content: (<span>{template.description}</span>) },
        {
          content: (<span>
            {(isTemplateAvailable(template)) && (
              <Badge color="success" className="mr-1">{t('globals.enabled')}</Badge>
            )}
            {!(isTemplateAvailable(template)) && (
              <Badge color="danger" className="mr-1">{t('globals.disabled')}</Badge>
            )}
          </span>)
        },
        {
          content: (<span>
            <Edit className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => { setEditModalShowIndex(index) }} />
            <Download className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => exportTemplate(template.id)} />
            {(KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_APPLICATION)) && (
              <React.Fragment>
                <Plus className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => setPlusModalShownIndex(index)} />
                <Trash className="pointer" size={20} strokeWidth={2} color={'black'} onClick={() => setDeleteModalShown(index)} />
              </React.Fragment>
            )}
            <AddContentModal template={template} show={plusModalShownIndex == index} onHide={() => setPlusModalShownIndex(-1)} afterCreation={(updatedTemplate) => {
              loadData()
            }} />
            <ViewEditTemplateModal template={template} afterUpdate={(updatedTemplate) => {
              loadData()
            }} show={editModalShowIndex == index} onHide={() => setEditModalShowIndex(-1)} />
            <ConfirmModal
              confirmationText={t('globals.ask-for-confirmation')}
              onConfirm={deleteTemplate}
              show={deleteModalShown === index}
              onHide={() => setDeleteModalShown(-1)} />
          </span>)
        },
      ]
    };
  };

  return (<React.Fragment>

    <CreateTemplateModal
      is_main_template={true}
      application={application}
      afterCreation={(template) => { loadData(); }}
      onHide={() => setCreationModalShown(false)}
      show={creationModalShown}
    />
    <Card>
      <Card.Header>
        <Card.Title>{t('templates.main-template')}</Card.Title>

        <Card.Options>
          {(KeycloakService.hasRole(AuthService.AUTHORIZATIONS.PRO_TEMPLATE)) && (
            <Button icon="plus" onClick={() => setCreationModalShown(true)} color="primary">
              {t('globals.create')}
            </Button>
          )}
        </Card.Options>
      </Card.Header>

      <Card.Body>


        {loading && <Loader />}

        {((!templates || !templates.length) && !loading) ? (
          <EmptyContent text={t('globals.no-results')} />) : ''}

        {((templates && templates.length) && !loading) ? (
          <React.Fragment>
            <Table responsive
              className="card-table"
              sortableObject={sort}
              headerItems={headerItems}
              bodyItems={templates.map(itemRow)}
            />
          </React.Fragment>
        ) : ''}

      </Card.Body>
    </Card>
  </React.Fragment>)
};

export default withI18n()(MainTemplatesList);
