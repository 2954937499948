// @flow
import EmptyContent from "components/common/empty-content/EmptyContent";
import ApplicationUploadModal from "components/modals/application-upload-modal/ApplicationUploadModal";
import * as React from "react";
import { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import ApplicationsService from "services/domain/ApplicationsService";
import AuthService from "services/domain/AuthService";
import KeycloakService from "services/domain/KeycloakService";
import UsersService from "services/domain/UsersService";
import { Button, Grid, Loader, Page } from "tabler-react";
import SiteWrapper from "../../../SiteWrapper";
import ApplicationsList from "./components/applications-list/ApplicationsList";
import CreationModal from "./components/creation-modal/CreationModal";


function PromoCodesPage({ t }): React.Node {
  const [userApplications, setUserApplications] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creationModalShown, setCreationModalShown] = useState(false);
  const [uploadAppModalShown, setUploadAppModalShown] = useState(false);
  // const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

  const loadData = () => {
    console.log("loadData");
    setLoading(true);

    UsersService.getMyApplications()
      .then(data => {
        console.log("data", data);
        setUserApplications(data)
      })
      .finally(() => setLoading(false));
  };

  const onSubmitCallback = (body) => {
    return ApplicationsService.import(body)
      .then();
  };

  useEffect(() => {
    document.title = t('navbar.applications');
    loadData()
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <CreationModal afterCreation={(application) => {
          userApplications.push(application);
          window.location.reload()
        }} show={creationModalShown} onHide={() => setCreationModalShown(false)} />
        <ApplicationUploadModal afterCreation={(application) => {
          // userApplications.push(application);
          window.location.reload()
        }} show={uploadAppModalShown} onHide={() => setUploadAppModalShown(false)} onSubmitCallback={onSubmitCallback} />
        <Page.Header
          title={t('navbar.applications')}
          options={(
            <Grid.Row>
              <Grid.Col>
                {(!loading && KeycloakService.hasRole(AuthService.AUTHORIZATIONS.ADMIN_APPLICATION)) && (
                  <Button.List>
                    <Button icon="upload" onClick={() => setUploadAppModalShown(true)} color="info">{t('globals.upload')}</Button>
                  </Button.List>
                )}
              </Grid.Col>
              <Grid.Col>
                {(!loading && KeycloakService.hasRole(AuthService.AUTHORIZATIONS.ADMIN_APPLICATION)) && (
                  <Button.List>
                    <Button icon="plus" onClick={() => setCreationModalShown(true)} color="primary">{t('globals.create')}</Button>
                  </Button.List>
                )}
              </Grid.Col>

              <Grid.Col>
              </Grid.Col>
            </Grid.Row>)}
        />

        {loading && <Loader />}

        {(!loading && userApplications && userApplications.length > 0) && (
          <ApplicationsList userApplications={userApplications} />
        )}

        {(!loading && (!userApplications || !userApplications.length)) && (
          <EmptyContent text={t('globals.no-results')} />
        )}

      </Page.Content>
    </SiteWrapper>
  );
}

export default withI18n()(PromoCodesPage);
