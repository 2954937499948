import BeePlugin from '@mailupinc/bee-plugin';
import { contentTemplateLanguage } from "models/Template";
import React, { useEffect, useState } from 'react';
import { withI18n } from "react-i18next";
import TemplatesService from 'services/domain/TemplatesService';
import LinksProvider from "services/http/LinksProvider";
import { Icon } from 'tabler-react';
import "./BeeTemplate.scss";
import ModificationObjectModal from './modify-template/ModificationObjectModal';
import PreviewTagsModal from './modify-template/PreviewTagsModal';

// Move credentials to backend for production.
const CLIENT_ID = "7eec7158-2ba3-4b15-b20b-55f56813d1a7"
const CLIENT_SECRET = "CJuvrqhWSOvxSEbmHikihQm6CsvMp4UGtZPnl8U7etjfxHsCvBQ"
const CLIENT_UID = "username"

const BASE_TEMPLATE = "https://rsrc.getbee.io/api/templates/a-bee";
const DEFAULT_CONFIGURATION = {
  uid: CLIENT_UID,                   // Needed for identify resources of the user.
  container: 'bee-plugin-container', // Identifies the id of div element that contains BEE Plugin.
  language: 'fr-FR',
  autosave: true,
}

function BeeTemplatePage({ t, baseTemplate, onSend, onStart, onSaveAsTemplate, beeConfig, match }): React.Node {

  const [contentTemplate, setContentTemplate] = useState(null);
  const [modificationObjectModalShown, setModificationObjectModalShown] = useState(false);
  const [previewTagsModalShown, setPreviewTagsModalShown] = useState(false);

  useEffect(() => {
    document.title = `${t('templates.title-edit-content')}`;
    const beeFinalConfig = { ...DEFAULT_CONFIGURATION, ...beeConfig, onSave, onSaveAsTemplate, onSend, onStart }

    return onFetchTemplate()
      .then((template) => {
        const beeEditor = new BeePlugin()
        onFetchBeeToken(CLIENT_ID, CLIENT_SECRET, beeEditor)
          .then(() => beeEditor.start(beeFinalConfig, template))
      })
      .catch((error) => {
        console.error(error)
      })
  }, []);

  const onFetchBeeToken = (clientId, clientSecret, beeEditor) => {
    return beeEditor.getToken(clientId, clientSecret)
  };

  const onFetchTemplate = () => {
    return TemplatesService.getContentTemplate(match.params.template_id, match.params.content_id)
      .then((response) => {
        console.log("response", response);
        setContentTemplate(response);
        try {
          if (response.json_content) {
            if (typeof response.json_content === "string") {
              return JSON.parse(response.json_content);
            }
            return response.json_content;
          }
          return fetch(BASE_TEMPLATE)
            .then((response) => response.json())
        } catch (e) {
          return fetch(BASE_TEMPLATE)
            .then((response) => response.json())
        }
      }
      )
  };

  const onSave = (jsonFile, htmlFile) => {
    let contentTemplateBody = {
      json_content: jsonFile,
      html_content: htmlFile
    }
    TemplatesService.saveContentTemplate(match.params.template_id, match.params.content_id, contentTemplateBody)
      .then(saveContentTemplate => {
      })
  };

  return (
    <React.Fragment>
      {(contentTemplate) && (
        <React.Fragment>
          <div class="plugin-toolbar-container">
            <div class="plugin-toolbar">

              <span class="plugin-toolbar-label">
                <a href={LinksProvider.formatUrl(LinksProvider.ROUTES.APPLICATION, { id: contentTemplate.template.application })} >
                  <Icon name="arrow-left" prefix="fe" />
                </a>
              </span>

              <span class="plugin-toolbar-label">
                {t('templates.template-name')} : {contentTemplate.template.name}
              </span>
              <span class="plugin-toolbar-label">
              </span>
              <span class="plugin-toolbar-label">
                {t('globals.language')} : {(contentTemplateLanguage.map((language) => (language.value === contentTemplate.language) ? language.label : null))}
              </span>
              <span class="plugin-toolbar-label">
                {t('templates.object')} : {contentTemplate.object}
              </span>
              <span class="btn btn-primary pull-right" onClick={() => setModificationObjectModalShown(true)}>{t('templates.modify-object')}</span>
              <span class="btn btn-warning pull-right" onClick={() => setPreviewTagsModalShown(true)}>{t('templates.preview-tags')}</span>
              <ModificationObjectModal content_id={match.params.content_id} template_id={match.params.template_id} contentTemplate={contentTemplate}
                afterHide={(updatedContentTemplate) => { }} show={modificationObjectModalShown} onHide={() => setModificationObjectModalShown(false)} />
              <PreviewTagsModal content_id={match.params.content_id} template_id={match.params.template_id} contentTemplate={contentTemplate}
                afterHide={(updatedContentTemplate) => { }} show={previewTagsModalShown} onHide={() => setPreviewTagsModalShown(false)} />
            </div>
          </div>

          <div id="bee-plugin-container" />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default withI18n()(BeeTemplatePage);
