/**
 * UsersService
 * @module Services/http/TicketsService
 * @description Loads users data from the server
 */

import { HttpBackendClient } from "../http/HttpBackendService";
import LinksProvider from "../http/LinksProvider";

const self = {};


self.getApplications = (): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.GET_APPLICATIONS)
    .then(data => data);
};

self.getApplication = (id: string): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.GET_APPLICATION_DETAILS, { id })
    .then(data => data);
};

self.createApplication = (data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.CREATE_APPLICATION, null, data)
    .then(data => data);
};

self.update = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.UPDATE_MY_APPLICATION, { id }, data)
    .then(data => data);
};

self.updateAdmin = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.UPDATE_ADMIN_APPLICATION, { id }, data)
    .then(data => data);
};

self.export = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.client({
    url: HttpBackendClient.formatFullUrl(LinksProvider.API.APPLICATION.EXPORT_MY_APPLICATION.path, { id }),
    method: LinksProvider.API.APPLICATION.EXPORT_MY_APPLICATION.method,
    responseType: 'blob',
    headers: {
      'Keep-Original-Response': true
    }
  });
};

self.import = (data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.IMPORT_APPLICATION, null, data)
    .then(data => data);
};

self.importMyApp = (id: string, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.IMPORT_MY_APPLICATION, { id }, data)
    .then(data => data);
};

self.getEmailHistory = (id: string, criteria, data): Promise<PromoCode> => {
  return HttpBackendClient.method(LinksProvider.API.APPLICATION.EMAIL_HISTORY, { id, ...criteria }, data)
    .then(data => data);
};

export default self;
